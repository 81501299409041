@font-face {
  font-family: 'Fredericka the Great';
  font-style: normal;
  font-weight: 400;
  src: local("Fredericka the Great"), local("FrederickatheGreat"), url(/assets/FrederickaTheGreat.ttf) format("truetype"); }

@font-face {
  font-family: 'Bahiana';
  font-style: normal;
  font-weight: 400;
  src: local("Bahiana Regular"), local("Bahiana-Regular"), url(/assets/Bahiana.ttf) format("truetype"); }

@font-face {
  font-family: 'Love Ya Like A Sister';
  font-style: normal;
  font-weight: 400;
  src: local("Love Ya Like A Sister Regular"), local("LoveYaLikeASister-Regular"), url(/assets/LoveYaLikeASister.ttf) format("truetype"); }

@font-face {
  font-family: 'Overpass Mono';
  src: local("Overpass Mono"), local("OverpassMono-Regular"), url(/assets/OverpassMono-Regular.ttf); }

#title-page {
  max-width: 500px; }
  #title-page .title-page__logo {
    width: 100%;
    height: auto;
    --svg-fill: var(--primary-color); }
  #title-page .enter-link {
    float: right;
    font-size: 2em; }
