:root {
  --background-color: #fafbf7;
  --primary-color: black;
  --primary-color--muted: #111;
  --secondary-color: grey;
  --tertiary-color: lightgrey;
  --color-emphasis: #1900ea;
  --color-less-emphasis: #54198b;

  @media (prefers-color-scheme: dark) {
    --background-color: #333;
    --primary-color: #f6f7dd;
    --primary-color--muted: #d6d7bd;
    --secondary-color: #4affee;
    --secondary-color--muted: #4affee44;
    --tertiary-color: #31ada1;
    --color-emphasis: #a8a2ff;
    --color-less-emphasis: #817cc5;
  }
}
