@font-face {
  font-family: 'Fredericka the Great';
  font-style: normal;
  font-weight: 400;
  src: local("Fredericka the Great"), local("FrederickatheGreat"), url(/assets/FrederickaTheGreat.ttf) format("truetype"); }

@font-face {
  font-family: 'Bahiana';
  font-style: normal;
  font-weight: 400;
  src: local("Bahiana Regular"), local("Bahiana-Regular"), url(/assets/Bahiana.ttf) format("truetype"); }

@font-face {
  font-family: 'Love Ya Like A Sister';
  font-style: normal;
  font-weight: 400;
  src: local("Love Ya Like A Sister Regular"), local("LoveYaLikeASister-Regular"), url(/assets/LoveYaLikeASister.ttf) format("truetype"); }

@font-face {
  font-family: 'Overpass Mono';
  src: local("Overpass Mono"), local("OverpassMono-Regular"), url(/assets/OverpassMono-Regular.ttf); }

#table-of-contents table {
  border-collapse: collapse;
  width: 100%; }

#table-of-contents table th {
  font-family: "Fredericka the Great", cursive; }

#table-of-contents table td {
  padding-left: 0.25em; }

#table-of-contents table th,
#table-of-contents table td {
  border: 1px solid var(--tertiary-color); }
