@font-face {
  font-family: 'Fredericka the Great';
  font-style: normal;
  font-weight: 400;
  src: local("Fredericka the Great"), local("FrederickatheGreat"), url(/assets/FrederickaTheGreat.ttf) format("truetype"); }

@font-face {
  font-family: 'Bahiana';
  font-style: normal;
  font-weight: 400;
  src: local("Bahiana Regular"), local("Bahiana-Regular"), url(/assets/Bahiana.ttf) format("truetype"); }

@font-face {
  font-family: 'Love Ya Like A Sister';
  font-style: normal;
  font-weight: 400;
  src: local("Love Ya Like A Sister Regular"), local("LoveYaLikeASister-Regular"), url(/assets/LoveYaLikeASister.ttf) format("truetype"); }

@font-face {
  font-family: 'Overpass Mono';
  src: local("Overpass Mono"), local("OverpassMono-Regular"), url(/assets/OverpassMono-Regular.ttf); }

body {
  margin: 0;
  background-color: var(--background-color);
  padding: 50px 10px 50px 10px;
  font-family: "Love Ya Like A Sister", cursive; }
  @media (min-width: 600px) {
    body {
      max-width: 700px;
      margin: 0 auto;
      padding: 100px 50px 50px 50px; } }
