@charset "UTF-8";
.bible-verse {
  quotes: '“' '”' '‘' '’';
  line-height: 2em;
  margin: 0; }
  .bible-verse .bible-verse__reference {
    color: var(--tertiary-color);
    font-size: 1.5em; }
  .bible-verse .bible-verse__block:before {
    font-size: 2em;
    content: open-quote; }
  .bible-verse .bible-verse__block:after {
    font-size: 2em;
    content: close-quote; }
  .bible-verse > ul {
    margin-left: 1em; }
    .bible-verse > ul li {
      list-style-type: none;
      margin-bottom: 0.75em;
      text-indent: -3em; }
    .bible-verse > ul li:first-of-type:before {
      font-size: 2em;
      content: open-quote; }
    .bible-verse > ul li:last-of-type:after {
      font-size: 2em;
      content: close-quote; }
