.blog-navigation {
  position: fixed;
  background-color: var(--background-color);
  border-color: var(--tertiary-color);
  --svg-fill: var(--tertiary-color); }
  @media (min-width: 600px) {
    .blog-navigation {
      background-color: transparent;
      border-color: transparent;
      border-style: solid; } }
  .blog-navigation svg {
    width: 2em;
    height: 2em; }
  .blog-navigation.blog-navigation--right {
    right: 0;
    bottom: 0;
    padding: 1em 0.5em 0.5em 1em;
    border-width: 1px 0 0 1px;
    border-top-left-radius: 4em; }
    @media (min-width: 600px) {
      .blog-navigation.blog-navigation--right {
        top: 50%;
        bottom: unset;
        padding: 1em 0.5em 1em 1em; } }
  .blog-navigation.blog-navigation--left {
    left: 0;
    bottom: 0;
    padding: 1em 1em 0.5em 0.5em;
    border-width: 1px 1px 0 0;
    border-top-right-radius: 4em; }
    @media (min-width: 600px) {
      .blog-navigation.blog-navigation--left {
        top: 50%;
        bottom: unset;
        padding: 1em 1em 1em 0.5em; } }
  .blog-navigation.blog-navigation--top-left {
    left: 0;
    top: 0;
    border-bottom-right-radius: 4em;
    border-width: 0 1px 1px 0;
    padding: 0.5em 1em 1em 0.5em; }
  @media (min-width: 600px) {
    .blog-navigation {
      border: unset; } }
