@font-face {
  font-family: 'Fredericka the Great';
  font-style: normal;
  font-weight: 400;
  src: local("Fredericka the Great"), local("FrederickatheGreat"), url(/assets/FrederickaTheGreat.ttf) format("truetype"); }

@font-face {
  font-family: 'Bahiana';
  font-style: normal;
  font-weight: 400;
  src: local("Bahiana Regular"), local("Bahiana-Regular"), url(/assets/Bahiana.ttf) format("truetype"); }

@font-face {
  font-family: 'Love Ya Like A Sister';
  font-style: normal;
  font-weight: 400;
  src: local("Love Ya Like A Sister Regular"), local("LoveYaLikeASister-Regular"), url(/assets/LoveYaLikeASister.ttf) format("truetype"); }

@font-face {
  font-family: 'Overpass Mono';
  src: local("Overpass Mono"), local("OverpassMono-Regular"), url(/assets/OverpassMono-Regular.ttf); }

#blog section#main-verse {
  padding-bottom: 2em; }

#blog .blog__table-of-contents {
  list-style-type: none;
  font-size: 1.5em;
  font-family: "Fredericka the Great", cursive;
  margin: 0;
  padding: 8px 16px 16px 8px;
  position: fixed;
  left: 0;
  top: 50px;
  text-align: center; }
  @media (max-width: 600px) {
    #blog .blog__table-of-contents {
      display: none; } }
  #blog .blog__table-of-contents a {
    --up-arrow: "\0027b6";
    --right-arrow: "\0027b5";
    --down-arrow: "\0027b4";
    --arrow: var(--down-arrow);
    position: relative; }
    #blog .blog__table-of-contents a, #blog .blog__table-of-contents a:visited, #blog .blog__table-of-contents a:active {
      color: var(--tertiary-color);
      display: block;
      width: 1em;
      margin-bottom: 3px; }
    #blog .blog__table-of-contents a:hover {
      color: var(--secondary-color); }
    #blog .blog__table-of-contents a:after {
      opacity: 0.5;
      content: var(--arrow);
      position: absolute;
      font-size: 0.8em;
      top: 0.2em;
      right: -0.8em;
      z-index: -1; }

#blog section > ul {
  margin-top: 0; }
  #blog section > ul li {
    list-style-type: none;
    text-indent: -1.5em;
    transform-origin: top left; }
    #blog section > ul li:nth-of-type(even) {
      transform: rotate(0.25deg); }
    #blog section > ul li:nth-of-type(odd) {
      transform: rotate(-0.25deg); }
  #blog section > ul li:before {
    padding-right: 0.5em; }

#blog #observations > ul > li:before {
  content: '\271e';
  /* cross */ }

#blog #interpretation > ul > li:before {
  content: '\2655';
  /* crown */ }

#blog #see-also > ul > li:before {
  content: '\21ab';
  /* squiggley arrow */ }

#blog #application > ul > li:before {
  content: '\1f463';
  /* footprints */ }

#blog ul > li > ul > li:before {
  content: '\27a5';
  /* down and right arrow */ }

#blog section {
  padding-top: 1em; }

#main-verse:target ~ .blog__table-of-contents #nav-for-main-verse {
  --arrow: var(--right-arrow); }

#main-verse:target ~ .blog__table-of-contents #nav-for-observations {
  --arrow: var(--down-arrow); }

#main-verse:target ~ .blog__table-of-contents #nav-for-interpretation {
  --arrow: var(--down-arrow); }

#main-verse:target ~ .blog__table-of-contents #nav-for-see-also {
  --arrow: var(--down-arrow); }

#main-verse:target ~ .blog__table-of-contents #nav-for-application {
  --arrow: var(--down-arrow); }

#observations:target ~ .blog__table-of-contents #nav-for-main-verse {
  --arrow: var(--up-arrow); }

#observations:target ~ .blog__table-of-contents #nav-for-observations {
  --arrow: var(--right-arrow); }

#observations:target ~ .blog__table-of-contents #nav-for-interpretation {
  --arrow: var(--down-arrow); }

#observations:target ~ .blog__table-of-contents #nav-for-see-also {
  --arrow: var(--down-arrow); }

#observations:target ~ .blog__table-of-contents #nav-for-application {
  --arrow: var(--down-arrow); }

#interpretation:target ~ .blog__table-of-contents #nav-for-main-verse {
  --arrow: var(--up-arrow); }

#interpretation:target ~ .blog__table-of-contents #nav-for-observations {
  --arrow: var(--up-arrow); }

#interpretation:target ~ .blog__table-of-contents #nav-for-interpretation {
  --arrow: var(--right-arrow); }

#interpretation:target ~ .blog__table-of-contents #nav-for-see-also {
  --arrow: var(--down-arrow); }

#interpretation:target ~ .blog__table-of-contents #nav-for-application {
  --arrow: var(--down-arrow); }

#see-also:target ~ .blog__table-of-contents #nav-for-main-verse {
  --arrow: var(--up-arrow); }

#see-also:target ~ .blog__table-of-contents #nav-for-observations {
  --arrow: var(--up-arrow); }

#see-also:target ~ .blog__table-of-contents #nav-for-interpretation {
  --arrow: var(--up-arrow); }

#see-also:target ~ .blog__table-of-contents #nav-for-see-also {
  --arrow: var(--right-arrow); }

#see-also:target ~ .blog__table-of-contents #nav-for-application {
  --arrow: var(--down-arrow); }

#application:target ~ .blog__table-of-contents #nav-for-main-verse {
  --arrow: var(--up-arrow); }

#application:target ~ .blog__table-of-contents #nav-for-observations {
  --arrow: var(--up-arrow); }

#application:target ~ .blog__table-of-contents #nav-for-interpretation {
  --arrow: var(--up-arrow); }

#application:target ~ .blog__table-of-contents #nav-for-see-also {
  --arrow: var(--up-arrow); }

#application:target ~ .blog__table-of-contents #nav-for-application {
  --arrow: var(--right-arrow); }
