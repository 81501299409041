@font-face {
  font-family: 'Fredericka the Great';
  font-style: normal;
  font-weight: 400;
  src: local("Fredericka the Great"), local("FrederickatheGreat"), url(/assets/FrederickaTheGreat.ttf) format("truetype"); }

@font-face {
  font-family: 'Bahiana';
  font-style: normal;
  font-weight: 400;
  src: local("Bahiana Regular"), local("Bahiana-Regular"), url(/assets/Bahiana.ttf) format("truetype"); }

@font-face {
  font-family: 'Love Ya Like A Sister';
  font-style: normal;
  font-weight: 400;
  src: local("Love Ya Like A Sister Regular"), local("LoveYaLikeASister-Regular"), url(/assets/LoveYaLikeASister.ttf) format("truetype"); }

@font-face {
  font-family: 'Overpass Mono';
  src: local("Overpass Mono"), local("OverpassMono-Regular"), url(/assets/OverpassMono-Regular.ttf); }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  margin-bottom: 0.25em;
  padding: 0;
  border: 0;
  font-size: 1em;
  font: inherit;
  vertical-align: baseline;
  padding-bottom: 5px;
  background-position: bottom left;
  background-size: 100% 5px;
  background-repeat: no-repeat;
  font-family: "Fredericka the Great", cursive; }

/* fallback because Safari doesn't accept sizes for background-size */
body.safari h1 {
  background-size: contain; }

body.safari h2,
body.safari h3,
body.safari h4,
body.safari h5,
body.safari h6 {
  background-size: auto; }

*:not(section) > .header {
  background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='462.859' height='11.558' viewBox='0 0 0 0 462.859 0 11.558' fill=''%3E%3Cpath d='M11.769 3.636c7.409.905 14.872.478 22.324 1.011 14.159.332 28.352-.678 42.496-.116 18.425-.453 36.871-1.826 55.302-1.439 22.635-.495 45.346-1.163 67.985-1.448 8.815-.354 17.654-.498 26.488-.342 9.517-.257 18.997.637 28.523.359 6.001-.008 11.989-.156 17.998-.304 20.356.119 40.689-.961 61.037-1.222 8.33.427 16.606-.366 24.932.34 5.689-.092 11.317.747 17.003.505 13.216-.36 27.011.299 40.149-.98 9.012.043 17.776.17 26.617 2.147 6.61 1.48 13.58.392 20.153 1.483.018.9.045 1.79.083 2.689-1.586.751-2.997 1.209-4.767 1.194-7.348.175-14.698.289-22.046.434-9.643.45-19.351-.859-28.984-1.42-8.414-.114-16.762-.47-25.183.086-10.095.879-19.792-.411-29.878-.102-15.315.401-30.611.722-45.924.754-12.018-.294-24.052.113-36.073.17-11.633.979-23.327.889-34.924 2.218-7.992.457-16.019.715-24.026.933-8.691.551-17.385-.108-26.088.363-5.962.438-11.933-.035-17.905.403-15.813.511-31.686.023-47.467-.916-11.088-.252-22.215.477-33.295.085-8.02-.902-16.023.005-24.026-.547-7.123-1.11-14.259.15-21.413-.959-9.344-.136-18.618-.754-27.927-1.631-4.266-.266-8.465.367-12.716.35C.148 6.766.079 5.797 0 4.828c3.84-.546 7.893-1.435 11.769-1.192z'/%3E%3C/svg%3E"); }

section:nth-of-type(6n + 1) > .header {
  background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='462.859' height='11.558' viewBox='0 0 0 0 462.859 0 11.558' fill=''%3E%3Cpath d='M11.769 3.636c7.409.905 14.872.478 22.324 1.011 14.159.332 28.352-.678 42.496-.116 18.425-.453 36.871-1.826 55.302-1.439 22.635-.495 45.346-1.163 67.985-1.448 8.815-.354 17.654-.498 26.488-.342 9.517-.257 18.997.637 28.523.359 6.001-.008 11.989-.156 17.998-.304 20.356.119 40.689-.961 61.037-1.222 8.33.427 16.606-.366 24.932.34 5.689-.092 11.317.747 17.003.505 13.216-.36 27.011.299 40.149-.98 9.012.043 17.776.17 26.617 2.147 6.61 1.48 13.58.392 20.153 1.483.018.9.045 1.79.083 2.689-1.586.751-2.997 1.209-4.767 1.194-7.348.175-14.698.289-22.046.434-9.643.45-19.351-.859-28.984-1.42-8.414-.114-16.762-.47-25.183.086-10.095.879-19.792-.411-29.878-.102-15.315.401-30.611.722-45.924.754-12.018-.294-24.052.113-36.073.17-11.633.979-23.327.889-34.924 2.218-7.992.457-16.019.715-24.026.933-8.691.551-17.385-.108-26.088.363-5.962.438-11.933-.035-17.905.403-15.813.511-31.686.023-47.467-.916-11.088-.252-22.215.477-33.295.085-8.02-.902-16.023.005-24.026-.547-7.123-1.11-14.259.15-21.413-.959-9.344-.136-18.618-.754-27.927-1.631-4.266-.266-8.465.367-12.716.35C.148 6.766.079 5.797 0 4.828c3.84-.546 7.893-1.435 11.769-1.192z'/%3E%3C/svg%3E"); }

section:nth-of-type(6n + 2) > .header {
  background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='383.455' height='8.772' viewBox='0 0 0 0 383.455 0 8.772'%3E%3Cpath d='M12.999 1.503C8.455 2.252 4.07 2.859 0 5.184c.13 1.159.25 2.318.36 3.487 4.465.572 8.828-1.455 13.337-1.564 8.019-.178 15.95-1.255 23.992-1.004 32.018.818 64.033.026 96.055.144 7.968.962 15.915.534 23.917.776 10.419.992 20.646.585 31.086.576 17.329-.187 34.67-.245 52-.312 8.795.555 17.494-.719 26.301.027 5.24.025 10.469-.099 15.703.256 21.304.479 42.714-.773 63.986.466 9.672.228 19.35-.004 29.031.023 2.33-.009 4.75.01 7.058-.32 1.555-1.753-.004-3.92-2.171-3.611-11.951-.029-23.89.173-35.852-.036-19.542-1.303-39.238.374-58.78-.9-12.693-.372-25.42.106-38.104-.376-16.041-1.214-32.187.524-48.161-1.101-11.342-.143-22.702-.057-34.047-.6-20.681-.134-41.379.232-62.06.177C86.66.067 69.737 1.001 52.762.525 39.463-.492 26.227.041 12.999 1.503z' fill='%23010101'/%3E%3C/svg%3E"); }

section:nth-of-type(6n + 3) > .header {
  background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='464.491' height='10.364' viewBox='0 0 0 0 464.491 0 10.364'%3E%3Cpath d='M370.741.051c9.906.362 19.861.154 29.74.886 6.812 1.141 13.69.694 20.515 1.665 11.869.784 23.835.54 35.719 1.004 3.134.401 6.553.497 7.776 3.948-.805.937-1.62 1.874-2.436 2.81-9.573-1.736-19.216-.364-28.792-1.93-6.26-.683-12.588-.216-18.855-1.099-6.926-.883-13.861-.656-20.803-1.21-11.296-.924-22.797-1.552-34.105-.547-5.097.347-10.11-.075-15.181.542-3.897.386-7.788.403-11.686.229-14.659-.17-29.336-.439-43.995-.639-5.668-.153-11.292.626-16.963.663-5.761.006-11.45-.087-17.19.469-8.999.503-18.069-.205-27.084.048-5.271-.037-10.521.538-15.801.541-11.656-.35-23.344 1.889-35.029 1.478-10.318-.248-20.812-.08-31.052-1.397-12.884-.461-25.801-.752-38.694-.643-19.902 1.195-39.056-.558-58.903.298-6.11.59-12.209.45-18.348.35-9.845.199-19.473-1.228-29.314.021.135-1.528-1.151-4.269.998-4.774 4.829-1.752 9.404-1.427 14.414-1.355 9.316.282 18.687.305 27.989-.313 26.056-.814 51.955 1.32 78.039.656 14.012-.581 28.027-.202 42.043-.443 4.612-.055 9.131.619 13.75.124 7.492-.697 14.98.105 22.484-.662 17.208-.329 34.429.332 51.626.023 11.548-.971 23.01.236 34.583-.495 10.862-.002 21.657.974 32.528.982 11.632.01 23.536 1.055 35.049-.267 5.713-.117 11.253-1.267 16.978-.963z' fill='%23010101'/%3E%3C/svg%3E"); }

section:nth-of-type(6n + 4) > .header {
  background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='452.778' height='9.261' viewBox='0 0 0 0 452.778 0 9.261'%3E%3Cpath d='M39.522.52c-8.801-.785-17.82-.636-26.574.558C8.26 1.668 4.137.713.023 3.63c-.35 4.269 3.311 5.574 6.883 5.631 7.676-.268 15.35.243 23.011-.225 20.674-.491 41.353-1.212 62.037-1.303 18.667.116 37.316-.158 55.979-.262 8.495.122 17.105.462 25.569-.315 4.408-.593 8.808-.645 13.244-.418 8.488.672 16.972-.135 25.462.638 5.273.057 10.623.013 15.857-.719 5.227-.632 10.572-.466 15.839-.679 10.328-.19 20.662-.561 30.996-.531 4.256-.199 8.472-.447 12.723-.026 6.861.821 13.721-.099 20.618.541 10.911.756 21.816-.428 32.7.499 3.011 0 5.994-.329 8.999-.179 14.632.36 29.391-1.164 43.971-.013 8.209.299 16.193-.487 24.343.274 7.853.438 15.833-.078 23.705-.071 2.9-.048 5.913.052 8.761-.474 2.735-.274 2.754-5.446-.007-5.461-14.921-.314-29.954 1.006-44.852-.007-9.929-.279-19.892.593-29.797.053-8.64-.769-17.633.07-26.27-.139-11.69-.709-23.481-.624-35.124.657-4.305.28-8.676-.128-12.906-.09-5.629.101-11.273.432-16.908.283-16.324.03-32.697.921-49.005.8C224.174.606 212.51.587 200.78 1.16c-12.675.795-25.348.369-38.044.705-15.296-.545-30.562-.179-45.854-.094-9.454-.93-18.468-.11-28.123-.206-6.374-.091-12.722.026-19.082-.306-5.886-.213-11.861.536-17.621-.27C47.862.416 43.728.762 39.522.52z' fill='%23010101'/%3E%3C/svg%3E"); }

section:nth-of-type(6n + 5) > .header {
  background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='452.155' height='9.247' viewBox='0 0 0 0 452.155 0 9.247'%3E%3Cpath d='M98.62.59c9.9-.12 19.87-.46 29.76.24 9.67.91 19.48.35 29.19.52 4.72.21 9.25-.97 13.95-.96 15.01-.12 30.02 0 45.03-.12 9.7-.21 19.3.22 29-.27 26.43.33 52.58.43 79 .77 6.03-.07 11.97.78 18 .42 18.97.19 37.87-.1 56.82-.22 14.08 0 28.14.5 42.18.23 3.81.04 7.25-.53 10.54 1.76.04 1.29.1 2.56.04 3.84-.9 1.59-2.93 1.53-4.52 1.75-8.01 0-16.07-.41-24.07.19-19.42 1.38-39.95-.48-59.05-.61-17.33-1.13-34.55-.67-51.94-.96-9.8.07-19.53-.76-29.33-.33-11.91-.2-23.8.01-35.7-.2-7.34.27-14.63-.5-21.97-.26-23.79-.39-47.88.26-71.58-.68-18.13.5-36.27.61-54.41.6-6.72-.15-13.29 1.14-20.01.95C74.16 7 68.96 8.31 63.59 8.26c-16.33.05-32.65.08-48.97-.18-3.67-.18-7.32-.65-10.99-.89C1.96 7.22 1.2 6.24 0 5.28l.99-2.64c8.76-.8 17.61.12 26.4-.58 15.28-1.01 30.56-.84 45.85-.14 8.47-.8 16.93-.25 25.38-1.33z' fill='%23010101'/%3E%3C/svg%3E"); }

h1 {
  font-size: 2.5em; }

h2,
h3 {
  font-size: 1.5em; }

.header__subtitle {
  font-size: 0.7em;
  color: var(--primary-color--muted); }
